import bvLogo from './bv-logo.png';
import bvHeadquartersFront from './bv-headquarters-front.jpg';
import bvHeadquartersFrontXs from './bv-headquarters-front-xs.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <a href='https://www.bv.com/'>
                <img src={bvLogo} alt="BV Logo" className="float-start img-fluid m-5" />
              </a>
            </div>
            <div className="col d-flex align-items-center justify-content-end">
              <a className="text-nowrap me-sm-5 me-2 header-link" href='mailto:COVID@bv.com'>Contact Us</a>
              <a className="text-nowrap header-link me-sm-4" href="https://www.bv.com/solutions/covid-19">Learn More</a>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="position-relative">
              <picture>
                <source media="(max-width: 575px)" srcSet={bvHeadquartersFrontXs} />
                <source media="(min-width: 576px)" srcSet={bvHeadquartersFront} />
                <img src={bvHeadquartersFront} srcSet={bvHeadquartersFront} alt="BV Headquarters" className="img-fluid bv-headquarters-img" />
              </picture>
              <div className="position-absolute top-0 start-0 w-100">
                <div className="text-center bv-disclaimer py-lg-5 pb-md-3 pb-sm-3 pb-2">
                  Black &amp; Veatch requires anyone entering our U.S. office locations to complete a health questionnaire in advance of their visit.
                </div>
                <div className="row gy-2">
                  <div className="offset-xxl-3 col-xxl-2 offset-md-2 col-md-4 offset-sm-2 col-sm-4 offset-2 col-8 text-center">
                    <a className="btn btn-bv-primary w-100 h-100 d-inline-flex align-items-center justify-content-center shadow py-md-3"
                      href="https://blackandveatch.sharepoint.com/sites/CESHS/SitePages/Covid-19.aspx">I'm a BV Professional</a>
                  </div>
                  <div className="offset-xxl-2 col-xxl-2 col-md-4 offset-md-0 offset-sm-0 col-sm-4 offset-2 col-8 text-center">
                    <a className="btn btn-bv-primary w-100 h-100 d-inline-flex align-items-center justify-content-center shadow py-md-3"
                      href="https://forms.office.com/FormsPro/Pages/ResponsePage.aspx?id=_LRTen3oRkyZcgVwrCcbJ2Bl3OIUNaFCvgy1HqCRk6NUMFNHUFdRVjM0TTE0SUdMMDdXVkpHM1o5Ny4u">I'm a Visitor</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mx-md-3 pt-1">
          <div className="col">
            <p><a href="https://www.bv.com/visitor-privacy-notice" className="learn-more-text"><b><u>Learn more</u></b></a><b>&nbsp;about our visitor privacy notice.</b></p>
            <h4 className="social-distancing-header">Hygiene and Social Distancing</h4>
            <p className="social-distancing-message">It is important to practice good hygiene and social distancing when visiting a Black &amp; Veatch office. Please remain at least 6 feet/2 meters from others when possible and limit physical contact.</p>
          </div>
        </div>
      </div>
      <footer id="global-footer" className="py-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <div className="block block--bv-footer">
                <ul block="bv_footer" className="nav">
                  <li className="nav-item  ">
                    <a href="https://www.bv.com/client-login" className="nav-link">Client Login</a>
                  </li>
                  <li className="nav-item  ">
                    <a href="https://blackandveatch.sharepoint.com/sites/BVConnect" className="nav-link">Employee Login</a>
                  </li>
                  <li className="nav-item  ">
                    <a href="https://www.bv.com/privacy-policy" className="nav-link" data-drupal-link-system-path="node/23">Privacy Policy</a>
                  </li>
                  <li className="nav-item  ">
                    <a href="https://www.bv.com/terms-use" className="nav-link" data-drupal-link-system-path="node/6061">Terms</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="block block--footer-social-media block--footersocialmedia">
                <a href="https://www.facebook.com/BlackVeatch/" target="_blank" rel="noreferrer">
                  <div className="social-media__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 96.124 96.123" style={{'enableBackground': 'new 0 0 96.124 96.123'}} xmlSpace="preserve">
                      <g><g>
                      <path d="M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803   c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654   c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246   c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF"></path>
                      </g></g>
                    </svg>
                  </div>
                </a>
                <a href="https://twitter.com/Black_Veatch" target="_blank" rel="noreferrer">
                  <div className="social-media__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style={{'enableBackground':'new 0 0 512 512'}} xmlSpace="preserve" width="512px" height="512px">
                      <g>
                        <g>
                          <g>
                            <path d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016    c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992    c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056    c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152    c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792    c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44    C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568    C480.224,136.96,497.728,118.496,512,97.248z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF"></path>
                          </g>
                        </g>
                      </g> 
                    </svg>
                  </div>
                </a>
                <a href="http://www.linkedin.com/company/black-and-veatch" target="_blank" rel="noreferrer">
                  <div className="social-media__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style={{'enableBackground':'new 0 0 512 512'}} xmlSpace="preserve" width="512px" height="512px" className="">
                      <g>
                        <g>
                          <g>
                            <rect y="160" width="114.496" height="352" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF"></rect>
                          </g>
                        </g>
                      <g>
                        <g>
                          <path d="M426.368,164.128c-1.216-0.384-2.368-0.8-3.648-1.152c-1.536-0.352-3.072-0.64-4.64-0.896    c-6.08-1.216-12.736-2.08-20.544-2.08c-66.752,0-109.088,48.544-123.04,67.296V160H160v352h114.496V320    c0,0,86.528-120.512,123.04-32c0,79.008,0,224,0,224H512V274.464C512,221.28,475.552,176.96,426.368,164.128z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF"></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <circle cx="56" cy="56" r="56" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF"></circle>
                        </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </a>

                <a href="https://www.instagram.com/black_veatch/?hl=en" target="_blank" rel="noreferrer">
                  <div className="social-media__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 169.063 169.063" style={{'enableBackground':'new 0 0 169.063 169.063'}} xmlSpace="preserve" className="">
                      <g>
                        <g>
                          <path d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752   c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407   c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752   c17.455,0,31.656,14.201,31.656,31.655V122.407z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF"></path>
                          <path d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561   C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561   c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF"></path>
                          <path d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78   c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78   C135.661,29.421,132.821,28.251,129.921,28.251z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF"></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                </a>

                <a href="https://www.youtube.com/user/BVGMBC09" target="_blank" rel="noreferrer">
                  <div className="social-media__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style={{'enableBackground':'new 0 0 512 512'}} xmlSpace="preserve" width="512px" height="512px" className="">
                      <g>
                        <g>
                          <g>
                            <path d="M490.24,113.92c-13.888-24.704-28.96-29.248-59.648-30.976C399.936,80.864,322.848,80,256.064,80    c-66.912,0-144.032,0.864-174.656,2.912c-30.624,1.76-45.728,6.272-59.744,31.008C7.36,138.592,0,181.088,0,255.904    C0,255.968,0,256,0,256c0,0.064,0,0.096,0,0.096v0.064c0,74.496,7.36,117.312,21.664,141.728    c14.016,24.704,29.088,29.184,59.712,31.264C112.032,430.944,189.152,432,256.064,432c66.784,0,143.872-1.056,174.56-2.816    c30.688-2.08,45.76-6.56,59.648-31.264C504.704,373.504,512,330.688,512,256.192c0,0,0-0.096,0-0.16c0,0,0-0.064,0-0.096    C512,181.088,504.704,138.592,490.24,113.92z M192,352V160l160,96L192,352z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF"></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col pt-4">
              <div className="block block--basic block--footercopyright-2">
                <div className="basic__body"><p>Copyright © 2021&nbsp;Black &amp; Veatch Holding Company. All rights reserved.</p></div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
